import React from "react";
import ReactDOM from "react-dom";
import LinkedInIcon from "./linkedin-icon.png";
import UnderConstruction from "./underConstruction.png";


import "./styles.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Sorry, Website <br/>
          is under construction 
          </h1>
          <img src={UnderConstruction} style={{width: "20%", minWidth: "200px"}}/>
          <p>Get in touch!</p>
          <a href="https://www.linkedin.com/in/christina-knabl-752aa9197/"><img src={LinkedInIcon} style={{width: "2%", minWidth: "50px"}}/> </a>
         
          
        
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
